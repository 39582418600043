@font-face {
  font-family: "Flaticon";
  src: url("Flaticon_1.eot");
  src: url("Flaticon_1d41d.eot?#iefix") format("embedded-opentype"),
       url("Flaticon_1.woff2") format("woff2"),
       url("Flaticon_1.woff") format("woff"),
       url("Flaticon_1.ttf") format("truetype"),
       url("Flaticon_1.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("Flaticon_1.svg#Flaticon") format("svg");
  }
}

.fimanager:before {
      display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
    display: block;
}

.flaticon-001-arrow-down:before { content: "\f100"; }
.flaticon-002-arrow-down:before { content: "\f101"; }
.flaticon-003-arrow-up:before { content: "\f102"; }
.flaticon-004-arrow-up:before { content: "\f103"; }
.flaticon-005-back-arrow:before { content: "\f104"; }
.flaticon-006-brightness:before { content: "\f105"; }
.flaticon-007-bulleye:before { content: "\f106"; }
.flaticon-008-check:before { content: "\f107"; }
.flaticon-009-check:before { content: "\f108"; }
.flaticon-010-check:before { content: "\f109"; }
.flaticon-011-check:before { content: "\f10a"; }
.flaticon-012-checkmark:before { content: "\f10b"; }
.flaticon-013-checkmark:before { content: "\f10c"; }
.flaticon-014-checkmark:before { content: "\f10d"; }
.flaticon-015-chevron:before { content: "\f10e"; }
.flaticon-016-double-chevron:before { content: "\f10f"; }
.flaticon-017-clipboard:before { content: "\f110"; }
.flaticon-018-clock:before { content: "\f111"; }
.flaticon-019-close:before { content: "\f112"; }
.flaticon-020-close:before { content: "\f113"; }
.flaticon-021-command:before { content: "\f114"; }
.flaticon-022-copy:before { content: "\f115"; }
.flaticon-023-cut:before { content: "\f116"; }
.flaticon-024-dashboard:before { content: "\f117"; }
.flaticon-025-dashboard:before { content: "\f118"; }
.flaticon-026-delete:before { content: "\f119"; }
.flaticon-027-dot:before { content: "\f11a"; }
.flaticon-028-download:before { content: "\f11b"; }
.flaticon-029-ellipsis:before { content: "\f11c"; }
.flaticon-030-ellipsis:before { content: "\f11d"; }
.flaticon-031-ellipsis:before { content: "\f11e"; }
.flaticon-032-ellipsis:before { content: "\f11f"; }
.flaticon-033-feather:before { content: "\f120"; }
.flaticon-034-filter:before { content: "\f121"; }
.flaticon-035-flag:before { content: "\f122"; }
.flaticon-036-floppy-disk:before { content: "\f123"; }
.flaticon-037-funnel:before { content: "\f124"; }
.flaticon-038-gauge:before { content: "\f125"; }
.flaticon-039-goal:before { content: "\f126"; }
.flaticon-040-graph:before { content: "\f127"; }
.flaticon-041-graph:before { content: "\f128"; }
.flaticon-042-menu:before { content: "\f129"; }
.flaticon-043-menu:before { content: "\f12a"; }
.flaticon-044-menu:before { content: "\f12b"; }
.flaticon-045-heart:before { content: "\f12c"; }
.flaticon-046-home:before { content: "\f12d"; }
.flaticon-047-home:before { content: "\f12e"; }
.flaticon-048-home:before { content: "\f12f"; }
.flaticon-049-home:before { content: "\f130"; }
.flaticon-050-info:before { content: "\f131"; }
.flaticon-051-info:before { content: "\f132"; }
.flaticon-052-inside:before { content: "\f133"; }
.flaticon-053-lifebuoy:before { content: "\f134"; }
.flaticon-054-maximize:before { content: "\f135"; }
.flaticon-055-minimize:before { content: "\f136"; }
.flaticon-056-minus:before { content: "\f137"; }
.flaticon-057-minus:before { content: "\f138"; }
.flaticon-058-minus:before { content: "\f139"; }
.flaticon-059-minus:before { content: "\f13a"; }
.flaticon-060-on:before { content: "\f13b"; }
.flaticon-061-outside:before { content: "\f13c"; }
.flaticon-062-pencil:before { content: "\f13d"; }
.flaticon-063-pencil:before { content: "\f13e"; }
.flaticon-064-pin:before { content: "\f13f"; }
.flaticon-065-pin:before { content: "\f140"; }
.flaticon-066-plus:before { content: "\f141"; }
.flaticon-067-plus:before { content: "\f142"; }
.flaticon-068-plus:before { content: "\f143"; }
.flaticon-069-plus:before { content: "\f144"; }
.flaticon-070-power:before { content: "\f145"; }
.flaticon-071-print:before { content: "\f146"; }
.flaticon-072-printer:before { content: "\f147"; }
.flaticon-073-question:before { content: "\f148"; }
.flaticon-074-question:before { content: "\f149"; }
.flaticon-075-reload:before { content: "\f14a"; }
.flaticon-076-remove:before { content: "\f14b"; }
.flaticon-077-remove:before { content: "\f14c"; }
.flaticon-078-remove:before { content: "\f14d"; }
.flaticon-079-search:before { content: "\f14e"; }
.flaticon-080-search:before { content: "\f14f"; }
.flaticon-081-search:before { content: "\f150"; }
.flaticon-082-share:before { content: "\f151"; }
.flaticon-083-share:before { content: "\f152"; }
.flaticon-084-share:before { content: "\f153"; }
.flaticon-085-signal:before { content: "\f154"; }
.flaticon-086-star:before { content: "\f155"; }
.flaticon-087-stop:before { content: "\f156"; }
.flaticon-088-time:before { content: "\f157"; }
.flaticon-089-trash:before { content: "\f158"; }
.flaticon-090-upload:before { content: "\f159"; }
.flaticon-091-warning:before { content: "\f15a"; }
.flaticon-092-warning:before { content: "\f15b"; }
.flaticon-093-waving:before { content: "\f15c"; }